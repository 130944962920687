import Navbar from "../Navbar/Navbar";
import ApplicationProgress from "./ApplicationProgress";
import MobileApplicationPage from "./MobileApplicationPage";

export default function ApplicationProgressPage() {
  const windowWidth = window.innerWidth;
    

  return (
    <>
      <Navbar>
        {windowWidth < 500 ? (
          <MobileApplicationPage />
        ) : (
          <ApplicationProgress />
        )}
      </Navbar>
    </>
  );
}
