import React, { useState } from 'react';
import '../../../Styles/Login/ToggleButton/ToggleButton.css';

const ToggleButton = () => {
  const [isToggled, setToggled] = useState(false);
  const switchContainerStyle =  "switch-container activeLeft";
//   active ? "switch-container activeRight" :
  const handleToggle = () => {
    setToggled(!isToggled);
  };

  return (
    <div className={switchContainerStyle}>
      
      <label className="toggle-switch">
        <input
          type="checkbox"
          className="checkbox"
        //   checked={!active}
          onChange={handleToggle}
        />
        <span className="slider round"></span>

      </label>
      <span className='rememberMe'>Remember me</span>
    </div>
  );
};

export default ToggleButton;