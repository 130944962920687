import React from "react";
import Navbar from "../Navbar/Navbar";
import KnowYourCompliance from "./KnowYourCompliance";

export default function KnowYourCompliancePage(){
    return<>
    <Navbar>
        <KnowYourCompliance/>
    </Navbar>
    </>
}