import React from "react";
import Navbar from "../../Navbar/Navbar";
import ApplicationStatus from "./ApplicationStatus";

export default function ApplicationStatusPage(){
    return<>
    <Navbar>
        <ApplicationStatus/>
    </Navbar>
    </>
}