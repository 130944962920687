import { useEffect, useState } from "react";
// import "../../../Styles/Input/Input.css";
import "../../../Styles/Pages/KnowYourCompliance/KnowYourCompliance.css";
import ComplianceCard from "./ComplianceCard/ComplianceCard";
// import logo from "../../../assets/eikomp_logo 1.png";
// import isi from "../../../assets/ComplianceCard/Rectangle 2783 (1).png";
import axios from "axios";
// import Select from "react-select";
// import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Swal from "sweetalert2";

// import Autosuggest from 'react-autosuggest';

export default function KnowYourCompliance() {
  const [industryDropdown, setIndustryDropdown] = useState([]);
  // const [suggestions, setSuggestions] = useState([]);
  const [kYCmessage, setKYCMessage] = useState(false);
 

  const [complianceData, setComplianceData] = useState([]);
  const [notFoundProduct, setNotfoundProduct] = useState("");

  // const [formData, setFormData] = useState({
  //   product_type: "",
  //   industry_name: "",
  //   region: "",
  //   target_country: "",
  // });

  const [productType, setProductType] = useState("");
  const [industry, setIndustry] = useState("");
  const [region, setRegion] = useState("");
  const [targetCountry, setTargetCountry] = useState("");
  const [loading, setLoading] = useState(false);
  const [invalidInput, setInvalidInput] = useState(false);
  // const [fetchSuccess, setFetchSuccess] = useState(false);
  const [productDropdown, setProductDropdown] = useState([]);


  const regions = [
    "North America",
    "South America",
    "Europe",
    "Asia",
    "Africa",
    "Oceania",
    "Antarctica",
  ];

  const countries = [
    "United States",
    "Brazil",
    "Germany",
    "India",
    "Nigeria",
    "Australia",
    "Canada",
    "Argentina",
    "France",
    "China",
    "South Africa",
    "Japan",
    "Mexico",
    "United Kingdom",
    "Italy",
    "Russia",
    "Kenya",
    "New Zealand",
    "South Korea",
    "Egypt",
  ];

  useEffect(() => {
    async function populateDropdown() {
      try {
        const res = await axios.get(
          "https://eikompbackend.eikompapp.com/accounts/industry/",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        console.log(res.data);
        setIndustryDropdown(res.data.data);
        // console.log(industryDropdown);

        // console.log(complianceDropdown);
      } catch (error) {
        console.log(error);
      }
    }
    populateDropdown();
  }, []);

  // suggestion api

  // useEffect(() => {
  //   async function suggestionCall() {
  //     console.log("calling suggestions");
  //     try {
  //       const res = await axios.get(
  //         "https://eikompbackend.eikompapp.com/products/",
  //         {
  //           headers: {
  //             Authorization: `Bearer ${localStorage.getItem("token")}`,
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );
  //       console.log(res);
  //       setSuggestions(res.data.data);
  //       // console.log(suggestions);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   suggestionCall();
  // }, []);

  

  const handleInputChange = async (event) => {
    const inputValue = event.target.value;
    setNotfoundProduct(inputValue);
    console.log(inputValue);
    setProductType(inputValue);
    console.log(productType);
    if (inputValue==="") {
      setProductDropdown([]);
      return; // Exit the function early if inputValue is null or empty
    }
  
    // Adding a 300ms delay using setTimeout
    setTimeout(async () => {
      try {
        const res = await axios.get(`https://eikompbackend.eikompapp.com/products/product/?name=${inputValue}`, {
          headers: {
            "Authorization": `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });
  
        console.log(res.data.data.results);
        if(res.data.data.results.length===0){
            setInvalidInput(!invalidInput)
            setProductDropdown([]);
            // console.log(invalidInput);
        }else{

          if (res.data && res.data.data) {
            setInvalidInput(false);
            const responseData = res.data.data.results;
            let uniqueValues = [];
    
            if (Array.isArray(responseData)) {
              // Use a Set to store unique values
              const uniqueSet = new Set();
    
              // Add unique values to the Set
              responseData.forEach((item) => {
                uniqueSet.add(item);
              });
    
              // Convert the Set back to an array
              uniqueValues = Array.from(uniqueSet);
            }
    
            setProductDropdown(uniqueValues);
            console.log(uniqueValues);
          } else {
            // Handle empty or invalid response data
            
            setInvalidInput(true); 
            
            console.log(notFoundProduct);
            console.log(invalidInput);
            setProductDropdown([]);
            
           
          }

        }
  
       
      } catch (error) {
        console.log(error);
      }
    }, 1000);
  };
  

  const handleProductDropdownClick = (product) => {
    setProductType(product);
    console.log(productType)
    setProductDropdown([]);
  };


  const ProductDropdown = (props) => {
    const { dropDownData } = props;
  
    // Create a new array with unique product names using Set
    const uniqueProductNames = Array.from(new Set(dropDownData
      .map(item => item && item.name)));
  
    return (
      <div
        className=""
        style={{
          position:"absolute",
          width: "43%",
          top: '70px',
          left:"17px",
    
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          boxShadow: "0px 0px 8px #ddd",
          borderRadius: "10px",
          marginLeft: "-0.2rem",
          marginTop: "-0.7rem",
          maxHeight: "90px",
          overflow: "hidden",
          padding: "0px 16px",
          border: "#082a71 solid 2px",
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.overflowY = "scroll";
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.overflowY = "hidden";
        }}
      >
        {uniqueProductNames.map((name, index) => (
          <span
            key={index + 1}
            style={{
              animationDuration: "300ms",
              opacity: 1,
              height: "auto",
              visibility: "visible",
              color: "black",
              cursor: "pointer",
              display: "block",
              borderBottom: "1px solid #082a71",
              marginBottom: ".5rem",
            }}
            onClick={() => {
              handleProductDropdownClick(name);
            }}
          >
            {name}
          </span>
        ))}
      </div>
    );
  };



  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    // setFetchSuccess(false); // Reset fetch success status
    console.log(productType,targetCountry,region,industry);
  
    // const selectedProductType = productType ? productType.value : "";
  
    try {
      const res = await axios.get(`https://eikompbackend.eikompapp.com/compliance/knowyourcompliance/?product=${productType}&country=${targetCountry}&region=${region}&industry=${industry}`, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      console.log(res);
      // Check if the fetch operation was successful and data is in the expected format
      if (res.data && res.data.data) {
        setComplianceData(res.data.data);
        setKYCMessage(false);
        // setFetchSuccess(true); // Mark fetch as successful
      } else {
        setComplianceData([]);
        setKYCMessage(true);
      }
      setTimeout(() => {
        setLoading(false); // Stop loading after 2 seconds
  
        
        
      }, 1000);
    } catch (error) {
      console.error("Failed to fetch compliance data:", error);
      // setFetchSuccess(false); // Mark fetch as unsuccessful
      setKYCMessage(true);
    } 
  };
  
  
  


  useEffect(() => {
    console.log(complianceData); 
  }, [complianceData]);
  

  const productNotFoundHandler = async() => {
    console.log(notFoundProduct);
    
    try {
        const res = await axios.get(`https://eikompbackend.eikompapp.com/products/notfound/?name=${notFoundProduct}`,
        {
          headers: {
            "Authorization": `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
        )    
        
     console.log(res);
     if(res.data.status==='success'){
      setInvalidInput(false);
      Swal.fire({
        icon: 'success',
        title: 'Mail Sent Successfully!',
        text: 'Your email has been sent successfully.',
      });
     }

    } catch (error) {
      console.log(error);
    }
  };

  

  return (
    <>
      {/* <main style={{backgroundColor:"#F2F4F6", display:"block",height:"100vh", flexDirection:"column"}}> */}
      <form onSubmit={submitHandler}>
        <div className="compliance-form-dropdown">
          {/* dropdown 1 */}
          <div className="compliance-form-row" style={{position:"relative",gap:"3rem"}} >
            <div style={{flexDirection:"column",width:"100%"}}>
           <input
              alt="product Type"
              className="compliance-input-fields-dummy"
              placeholder="Enter Product name"
              type="text"
              onChange={(e) => handleInputChange(e)}
              value={productType}
              autoComplete="off"
            />
            {productDropdown && productDropdown.length > 0 && (
          <ProductDropdown dropDownData={productDropdown} />
        )}
        <br/>{invalidInput && <p style={{textAlign:"center"}}>Product not Found <span style={{cursor:"pointer",color:"blue"}} onClick={productNotFoundHandler}>click Here</span></p>}</div>
            {/* <Select
              value={
                productType
                // ? {
                //     value: productType,
                //     label: productType,
                //   }
                // : null
              }
              className="compliance-input-fields"
              onChange={(selectedOption) => setProductType(selectedOption)}
              options={suggestions.map((product) => ({
                value: product.name,
                label: product.name,
              }))}
              // onInputChange={handleInputChange}
              isClearable={true}
              placeholder="Select a Product"
            /> */}

            <select
              id="industryDropdown"
              value={industry}
              onChange={(event) => setIndustry(event.target.value)}
              className="compliance-input-fields"
            >
              <option value="" className="compliance-input-fields">
                Select your Industry
              </option>
              {industryDropdown.map((i, index) => (
                <option
                  key={index}
                  value={i.name}
                  className="compliance-input-fields"
                >
                  {i.name}
                </option>
              ))}
            </select>
          </div>

          {/* dropdown 2 */}
          <div className="compliance-form-row">
            <select
              id="industryDropdown"
              value={region}
              onChange={(event) => setRegion(event.target.value)}
              className="compliance-input-fields"
            >
              <option value="" className="compliance-input-fields">
                Select your Region
              </option>
              {regions.map((industry, index) => (
                <option
                  key={index}
                  value={industry}
                  className="compliance-input-fields"
                >
                  {industry}
                </option>
              ))}
            </select>

            {/* dropdown 3 */}

            <select
              id="industryDropdown"
              value={targetCountry}
              onChange={(event) => setTargetCountry(event.target.value)}
              className="compliance-input-fields"
            >
              <option value="" className="compliance-input-fields">
                Select your Country
              </option>
              {countries.map((industry, index) => (
                <option
                  key={index}
                  value={industry}
                  className="compliance-input-fields"
                >
                  {industry}
                </option>
              ))}
            </select>
          </div>
          <button onClick={submitHandler}>SUBMIT</button>
        </div>
      </form>
      <h3 className="CompliancCard-heading">List of Compliance</h3>
      <div className="KnowyourCompliance">
      {
  loading ? <p>Loading...</p> : (
      <div className="compliance-list">
  {complianceData.length > 0 ? (
    complianceData.map((d, index) => (
      <ComplianceCard
        key={`${d.compliance.id}-${index}`}
        id={d.compliance.id}
        img={d.compliance.image}
        title={d.compliance.name}
        content={d.compliance.content}
      />
    ))
  ) : (
    <p>{kYCmessage ? "No compliance data available." : "Data Not Found..."}</p>
  )}
</div>
 )
}
</div>

      {/* </main> */}
    </>
  );
}
