import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';

export default function DoughnutChartDemo({ graphData }) {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const data = {
      labels: ['Completed Project', 'Inprogress Project'],
      datasets: [
        {
          data: [graphData.completed, graphData.inprogress],
          backgroundColor: ['#FFA500', '#0000FF'],
          hoverBackgroundColor: ['#FF8C00', '#00008B'],
        },
      ],
    };
    const options = {
      cutout: '70%',
      legend: {
        position: 'right',
        labels: {
          boxWidth: 20,
          fontStyle: 'bold',
          display: 'flex',
          flexDirection: 'column',
        },
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var dataset = data.datasets[tooltipItem.datasetIndex];
            var total = dataset.data.reduce((previousValue, currentValue) => {
              return previousValue + currentValue;
            });
            var currentValue = dataset.data[tooltipItem.index];
            var percentage = ((currentValue / total) * 100).toFixed(2) + '%';
            return `${data.labels[tooltipItem.index]}: ${percentage}`;
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, [graphData]);

  return (
    <div className='card'>
      <h2>Project Overview</h2>
      <Chart
        type='doughnut'
        data={chartData}
        options={chartOptions}
        style={{ width: '250px !important', height: '250px', padding: '1rem 5rem 0 0' }}
      />
    </div>
  );
}
