import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import "../../../../Styles/Pages/Analytics/Graphs.css";

export default function MultiAxisDemo({graphData}) {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

    if (!graphData || typeof graphData !== 'object' || Array.isArray(graphData)) {
      // If graphData is not an object or is undefined/null or is an array, set default data or handle the case accordingly
      console.error('Invalid graphData:', graphData);
      return;
    }

    const values1 = Object.values(graphData.year1);
    const values2 = Object.values(graphData.year2);
    
    const data = {
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "June", "July","Aug","Sep","Oct",'Nov',"Dec"],
      datasets: [
        {
          label: "year 1",
          fill: false,
        //   backgroundColor: "linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(26, 217, 68, 0.14) 100%)",
          borderColor: "#1AD944",
          yAxisID: "y",
          tension: 0.4,
          data:  values1,
        },
        {
          label: "year 2",
          fill: false,
          backgroundColor: "#3A6FF8",
          borderColor: "#3A6FF8",
          yAxisID: "y1",
          tension: 0.4,
          data:values2,
        },
      ],
    };
    const options = {
      stacked: false,
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
        y: {
          type: "linear",
          display: true,
          position: "left",
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
        y1: {
          type: "linear",
          display: true,
          position: "right",
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            drawOnChartArea: false,
            color: surfaceBorder,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, [graphData]);

  

  return (
    <div className="card">
      <Chart type="line" data={chartData} options={chartOptions} className="multiLine-graph" />
    </div>
  );
}
