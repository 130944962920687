import React, { useEffect, useState } from "react";
import "../../Styles/UserProfile/UserProfile.css";
import Input from "../Input/Input";
import axios from "axios";


export default function UserProfile({ userinfo }) {
  const [isEditing, setIsEditing] = useState(true);
  const [formData, setFormData] = useState({
    user_id : userinfo.id,
    first_name: userinfo.first_name,
    last_name: userinfo.last_name,
    email: userinfo.email,
    mobile: userinfo.mobile,
    username: userinfo.username,
    address: userinfo.address,
    user_type: userinfo.user_type,
  });

  

  useEffect(()=>{
    console.log(formData);
  }, [formData]);

  const [editedUser, setEditedUser] = useState({ ...formData });

  const handleEditClick = () => {
    setIsEditing(false);
  };
  const handleCancelClick = () => {
    setEditedUser({ ...formData });
    setIsEditing(true);
  };

  const handleSaveClick = async() => {
    
    // Update the user details and exit edit mode
    setFormData(editedUser);
    setIsEditing(true);
    console.log(formData);

    try {
      const response = await axios.put(
        `https://eikompbackend.eikompapp.com/accounts/user/${formData.user_id}/`,
       editedUser,
       {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
      );
      console.log(response);
    } catch (error) {
      // Handle other errors (e.g., network issues)
      console.log(error);
    }
  };
 

  const handleChange = (fieldName, value) => {
    setEditedUser({
      ...editedUser,
      [fieldName]: value,
    });
  };
  const svgIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={{ paddingRight: "10px" }}
    >
      <path
        opacity="0.2"
        d="M3 7.5L12 12.75L21 7.5L12 2.25L3 7.5Z"
        fill="#6E7485"
      />
      <path
        d="M3 16.5L12 21.75L21 16.5"
        stroke="#6E7485"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 12L12 17.25L21 12"
        stroke="#6E7485"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 7.5L12 12.75L21 7.5L12 2.25L3 7.5Z"
        stroke="#6E7485"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
  const typeOptions = [
    "corporate",
    "lab",
    "individual_consultant",
    "logistics",
    "student",
  ];
  return (
    <div className="userprofile-main">
      <section className="userprofile-header">
        <h2>{svgIcon}Basic Information</h2>

        <button onClick={handleEditClick}>Edit Profile</button>
      </section>
      <div>
        {isEditing ? (
          <section className="edit-userInfo"  style={{margin:"0 40px"}}>
            {/* <span>
              First Name :<p>{formData.first_name}</p>{" "}
            </span> */}
           {/* <Input
                  alt="First Name"
                  type="text"
                  title="First Name"
                  placeholder="Enter your First Name"
                  onInputChange={handleChange}
                  fieldName="first_name"
                  value={editedUser.first_name}
                  disabled={true}
                />
            <span>
              Last Name : <p>{formData.last_name}</p>{" "}
            </span>
            <span>
              Email : <p>{formData.email}</p>{" "}
            </span>
            <span>
              Phone number : <p>{formData.mobile}</p>{" "}
            </span>
            <span>
              username : <p>{formData.username}</p>{" "}
            </span>
            <span>
              User Type : <p>{formData.user_type}</p>{" "}
            </span>
            <span>
              Address : <p>{formData.address}</p>{" "}
            </span> */}
            <div className="signup-formrow">
                <Input
                  alt="First Name"
                  type="text"
                  title="First Name"
                  placeholder="Enter your First Name"
                  onInputChange={handleChange}
                  fieldName="first_name"
                  value={formData.first_name}
                  disabled={true}
                />
                <br />
                <Input
                  alt="Last Name"
                  type="text"
                  title="Last Name"
                  placeholder="Enter your Last Name"
                  onInputChange={handleChange}
                  fieldName="last_name"
                  value={formData.last_name}
                  disabled={true}
                />
              </div>
              <br />
              <div className="signup-formrow">
                <Input
                  alt="Email"
                  type="text"
                  title="Email"
                  placeholder="Enter your Email"
                  onInputChange={handleChange}
                  fieldName="email"
                  value={formData.email}
                  disabled={true}
                />
                <br />
                <Input
                  alt="mobile"
                  type="text"
                  title="Phone Number"
                  placeholder="+91"
                  onInputChange={handleChange}
                  fieldName="mobile"
                  value={formData.mobile}
                  disabled={true}
                />
              </div>
              <br />
              <div className="signup-formrow">
                <Input
                  alt="User name"
                  type="text"
                  title="User Name"
                  placeholder="Enter your Username"
                  onInputChange={handleChange}
                  fieldName="username"
                  value={formData.username}
                  disabled={true}
                />
                <br />
                <div className="form-dropdown">
                  {/* Type Dropdown  */}

                  <label htmlFor="typeDropdown" className="inputs-left">
                    Select Your Type:
                  </label>

                  <select
                    id="typeDropdown"
                    value={formData.user_type}
                    onChange={(event) => handleChange(event)}
                    disabled
                  >
                    <option value="">Select...</option>
                    {typeOptions.map((type, index) => (
                      <option key={index} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <br />
              <div className="signup-formrow">
                <Input
                  alt="address"
                  type="text"
                  title="Address"
                  placeholder="Full Address"
                  onInputChange={handleChange}
                  fieldName="address"
                  value={formData.address}
                  disabled={true}
                />
              </div>
          </section>
        ) : (
          <section className="edit-userInfo">
            <form className="form" onSubmit={handleSaveClick}>
              <div className="signup-formrow">
                <Input
                  alt="First Name"
                  type="text"
                  title="First Name"
                  placeholder="Enter your First Name"
                  onInputChange={handleChange}
                  fieldName="first_name"
                  value={editedUser.first_name}
                />
                <br />
                <Input
                  alt="Last Name"
                  type="text"
                  title="Last Name"
                  placeholder="Enter your Last Name"
                  onInputChange={handleChange}
                  fieldName="last_name"
                  value={editedUser.last_name}
                />
              </div>
              <br />
              <div className="signup-formrow">
                <Input
                  alt="Email"
                  type="text"
                  title="Email"
                  placeholder="Enter your Email"
                  onInputChange={handleChange}
                  fieldName="email"
                  value={editedUser.email}
                />
                <br />
                <Input
                  alt="mobile"
                  type="text"
                  title="Phone Number"
                  placeholder="+91"
                  onInputChange={handleChange}
                  fieldName="mobile"
                  value={editedUser.mobile}
                />
              </div>
              <br />
              <div className="signup-formrow">
                <Input
                  alt="User name"
                  type="text"
                  title="User Name"
                  placeholder="Enter your Username"
                  onInputChange={handleChange}
                  fieldName="username"
                  value={editedUser.username}
                />
                <br />
                <div className="form-dropdown">
                  {/* Type Dropdown  */}

                  <label htmlFor="typeDropdown" className="inputs-left">
                    Select Your Type:
                  </label>

                  <select
                    id="typeDropdown"
                    value={editedUser.user_type}
                    onChange={(event) => handleChange(event)}
                  >
                    <option value="">Select...</option>
                    {typeOptions.map((type, index) => (
                      <option key={index} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <br />
              <div className="signup-formrow">
                <Input
                  alt="address"
                  type="text"
                  title="Address"
                  placeholder="Full Address"
                  onInputChange={handleChange}
                  fieldName="address"
                  value={editedUser.address}
                />
              </div>
            </form>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <button onClick={handleCancelClick}>Cancel</button>
              <button onClick={handleSaveClick}>Save</button>
            </div>
          </section>
        )}
        <section></section>
      </div>
    </div>
  );
}
