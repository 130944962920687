import { useEffect, useState } from "react";
import "../../../../Styles/Pages/KnowYourCompliance/ComplianceCard/ComplianceCard.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";


export default function ComplianceCard(props) {
  const navigate = useNavigate();
  const [readMore, setReadMore] = useState(false);
  const [complianceData, setComplianceData] = useState([]);


  const ide = props.id;
  const readMoreHandle = () => {
    setReadMore(!readMore);
  }
  const directedToCardInfo=async ()=>{

    try {
      const res = await axios.get(
        `https://eikompbackend.eikompapp.com/compliance/compliance/${ide}/`,
        {
          headers: {
            "Authorization": `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log(res.data);
      setComplianceData(res.data);
      localStorage.setItem("compliance_id", res.data.data.id);
      navigate('/KnowYourCompliance/bis', { state: { res: res.data } });
      // console.log(complianceData);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(()=>{
    console.log(complianceData);
  }, [complianceData]);

  

  return (
    <div className={readMore ? "Compliance-Card-active":"Compliance-Card"}>
      {/* <div className="img-container"> */}
        <img
          src={props.img}
          alt="img"
          className="card-img"
        />
      {/* </div> */}
      <div className="Card-Content">
        <h3>{props.title}</h3>
        <p>
        {readMore ? props.content : props.content.slice(0, 105)}
        </p>{" "}
        {/* {readMore && (
          <p>
             is responsible for the harmonious development of the
            activities of standardization, marking and quality certification of
            goods and for matters connected therewith or incidental thereto.
          </p>
        )} */}
        <button onClick={readMoreHandle}>{readMore ? "Close":"ReadMore"}</button><br/>
        <button onClick={directedToCardInfo}>See more deatails</button>
      </div>
    </div>
  );
}
