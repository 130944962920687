import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import leftimg from "../../assets/stock-vector-geometric-abstract-backgrounds-design-composition-of-simple-geometric-shapes-on-a-blue-background-1973707196 2.png";
import logo from "../../assets/eikomp_logo 1.png";
import dashboard from "../../assets/91374 1.png";
import Input from "../Input/Input";
import "../../Styles/Signup/Signup.css";
import { FaEyeSlash } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";

export default function Signup() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState("");

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    user_type: "",
    address: "",
    username: "",
    password: "",
    confirm_password: "",
  });

  // const industryOptions = ["Electronics", "Mechanics", "IT", "Chemical"];
  const typeOptions = ["corporate", "lab", "individual_consultant", "logistics", "student"];

  const handleEmailChange = (fieldName, value) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleDropdownChange = (fieldName, event) => {
    const selectedValue = event.target.value;
    setFormData({
      ...formData,
      [fieldName]: selectedValue,
    });
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setFormData({
      ...formData,
      password: newPassword,
    });
    setShowPassword("");
  };
  const handleConfirmPasswordChange = (event) => {
    const newPassword = event.target.value;
    setFormData({
      ...formData,
      confirm_password: newPassword,
    });
    setShowConfirmPassword("");
  };

  const directToLogIn = () => {
    navigate("/");
  };
  const inputStyle = {
    position: "relative",
  };

  const buttonStyle = {
    position: "absolute",
    right: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    border: "none",
    background: "transparent",
    cursor: "pointer",
  };

  const FormSubmitHandler = (e) => {
    e.preventDefault();

    console.log(formData);

    if (formData.password !== formData.confirm_password) {
      alert("password not matched");
    }
  };

  const SignupFormSubmitHandler = async (e) => {
    
    try {
      // const form = new FormData();

      // // form("username", "udit");

      // // console.log(form);
      // console.log(formData + "hi");
      // console.log("hi");


      const response = await axios.post(
        "https://eikompbackend.eikompapp.com/register",
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        }
      );

      console.log(response);

      // Check the response status and handle accordingly
      if (response.data !== undefined) {
        // Assuming a successful response means the user is logged in
        directToLogIn();
      } else {
        // Handle other status codes or errors
        console.error("Sign-up failed:", response.data); // Adjust this based on the API response structure
      }
    } catch (error) {
      // Handle any network errors or exceptions
      console.error("Error during sign-up:", error.message);
    }
  };

  return (
    <main style={{ background: "#f0f2f5" }}>
      <div className="signup-left-section">
        <img src={leftimg} alt="left-img" />
        <img
          src={dashboard}
          alt="dashboard-img"
          className="dashboard-img"
          style={{ width: "20rem" }}
        />

        <h3>Eikomp is the go-to partner</h3>
        <p style={{ padding: " 0 40px" }}>
          At Eikomp, we are committed to helping businesses achieve product
          regulatory compliance, which is a critical component of success in
          today’s business world. Compliance such as BIS, EPR, TEC MTCTE
          protects businesses from legal and financial risks and enhances their
          credibility and reputation among stakeholders and customers.
        </p>
      </div>
      <div>
        <div className="form-top" style={{ paddingTop: "4rem" }}>
          <img src={logo} alt="eikomp-logo" />{" "}
          <h2 style={{ color: "#082a71" }}>
            One click, total compliance - simplifying the complexities of
            product compliances
          </h2>
        </div>
        <form className="form" onSubmit={FormSubmitHandler}>
          <div className="signup-formrow">
            <Input
              alt="First Name"
              type="text"
              title="First Name*"
              placeholder="Enter your First Name"
              onInputChange={handleEmailChange}
              fieldName="first_name"
              value={formData.first_name}
            />
            <br />
            <Input
              alt="Last Name"
              type="text"
              title="Last Name*"
              placeholder="Enter your Last Name"
              onInputChange={handleEmailChange}
              fieldName="last_name"
              value={formData.last_name}
            />
          </div>
          <br />
          <div className="signup-formrow">
            <Input
              alt="Email"
              type="text"
              title="Email*"
              placeholder="Enter your Email"
              onInputChange={handleEmailChange}
              fieldName="email"
              value={formData.email}
            />
            <br />
            <Input
              alt="mobile"
              type="text"
              title="Phone Number"
              placeholder="+91"
              onInputChange={handleEmailChange}
              fieldName="mobile"
              value={formData.mobile}
            />
          </div>
          {/* <br /> */}
          {/*<div className="signup-formrow">
            {/* Industry Dropdown 
            <div className="form-dropdown">
              <label htmlFor="industryDropdown" className="inputs-left">
                Select Your Industry:
              </label>
              <select
                id="industryDropdown"
                value={formData.industry_name}
                onChange={(event) => handleDropdownChange("industry_name", event)}
                className="input-fields"
              >
                <option value="" className="input-fields">
                  Select...
                </option>
                {industryOptions.map((industry, index) => (
                  <option key={index} value={industry} className="input-fields">
                    {industry}
                  </option>
                ))}
              </select>
            </div>
            <br />
            <div className="form-dropdown">
              {/* Type Dropdown 
              <label htmlFor="typeDropdown" className="inputs-left">
                Select Your Type:
              </label>

              <select
                id="typeDropdown"
                value={formData.industry_type}
                onChange={(event) => handleDropdownChange("industry_type", event)}
              >
                <option value="">Select...</option>
                {typeOptions.map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
                </div>*/}
          <br />
          <div className="signup-formrow">
            {/* <Input
              alt="address"
              type="text"
              title="Address"
              placeholder="Full Address"
              onInputChange={handleEmailChange}
              fieldName="address"
              value={formData.address}
            /> */}

            <Input
              alt="User name"
              type="text"
              title="User Name*"
              placeholder="Enter your Username"
              onInputChange={handleEmailChange}
              fieldName="username"
              value={formData.username}
            />
            <br />
            <div className="form-dropdown">
              {/* Type Dropdown  */}

              <label htmlFor="typeDropdown" className="inputs-left">
                Select Your Type:
              </label>

              <select
                id="typeDropdown"
                value={formData.user_type}
                onChange={(event) => handleDropdownChange("user_type", event)}
              >
                <option value="">Select...</option>
                {typeOptions.map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <br />
          <div className="signup-formrow">
            <Input
              alt="address"
              type="text"
              title="Address"
              placeholder="Full Address"
              onInputChange={handleEmailChange}
              fieldName="address"
              value={formData.address}
            />
          </div>
          <br />

          <div className="signup-formrow">
            {/*  password */}
            <div className="inputs">
              <div className="inputs-left">
                <span>Password</span>
              </div>
              <div className="input-fields" style={inputStyle}>
                <input
                  alt="password"
                  type={showPassword ? "text" : "password"}
                  value={formData.password}
                  onChange={handlePasswordChange}
                  placeholder="Enter password"
                  required
                />
                <button
                  type="button"
                  style={buttonStyle}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
            </div>
            <br />
            {/* confirm password */}

            <div className="inputs">
              <div className="inputs-left">
                <span>Confirm Password</span>
              </div>
              <div className="input-fields" style={inputStyle}>
                <input
                  alt="confirm-password"
                  type={showConfirmPassword ? "text" : "password"}
                  value={formData.confirm_password}
                  onChange={handleConfirmPasswordChange}
                  placeholder="Enter Confirm password"
                  required
                />
                <button
                  type="button"
                  style={buttonStyle}
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
            </div>
          </div>
          <div className="signup-formrow" style={{fontFamily:"poppins",padding:"5px",fontSize:"0.8rem"}}>
            *Password must contains at least 8 characters.<br/>
            *Must contain 1 special character. <br/>
            *Must contain one capital letter.
          </div>
          <br />
          
          <button className="signup-btn" onClick={SignupFormSubmitHandler}>
            Sign Up
          </button>
          <br />
          <div className="form-footer">
            Alrady have an account?{" "}
            <span
              style={{
                cursor: "pointer",
                fontWeight: "500",
                fontSize: "16px",
                color: "#082a71",
              }}
              onClick={directToLogIn}
            >
              Sign In!
            </span>
          </div>
        </form>
      </div>
    </main>
  );
}
