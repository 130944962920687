import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import Login from "./component/Login/Login";
import Signup from "./component/Signup/Signup";
import HomePage from "./component/Pages/Home/HomePage";
import KnowYourCompliancePage from "./component/Pages/KnowYourCompliance/KnowYourCompliancePage";
import PlanYourCompliancePage from "./component/Pages/PlanYourCompliance/PlanYourCompliancePage";
import ApplicationProgressPage from "./component/Pages/ApplicationProgress/ApplicationProgressPage";
import CardInfo from "./component/Pages/KnowYourCompliance/ComplianceCard/CardInfo";
import ApplicationStatusPage from "./component/Pages/ApplicationProgress/ApplicationStatus/ApplicationStatusPage";
import AnalyticsPages from "./component/Pages/Analytics/AnalyticsPage";
import UserProfilePage from "./component/UserProfile/UserProfilePage";
import NotificationPage from "./component/Pages/Navbar/Notification/NotificationPage";

function App() {
  return (
    <>
      <Router>
        <Routes>
          {/* Routes Here */}
          <Route path="/" element={<Login/>} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/userprofile" element={<UserProfilePage />} />
          <Route path= "/notification" element={<NotificationPage  />} />

          {/* pages routes */}

          <Route path="/Home" element={<HomePage />} />
          <Route path="/KnowYourCompliance" element={<KnowYourCompliancePage />} />
          <Route path="/PlanYourCompliance" element={<PlanYourCompliancePage />} />
          <Route path="/Analytics" element={<AnalyticsPages />} />
          
          {/* Sub-Pages] */}
          <Route path="/KnowYourCompliance/bis" element={<CardInfo />} />
          
          <Route path="/ApplicationProgress" element={<ApplicationProgressPage />} />
          <Route path="/ApplicationProgress/status" element={<ApplicationStatusPage />} />
          
        </Routes>
      </Router>
    </>
  );
}

export default App;
