import React, { useState } from "react";
import "../../../../Styles/Pages/ApplicationProgress/SearchBar.css"; // Import the CSS file for styling
import { FaSearch } from "react-icons/fa";

const SearchBar = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    console.log(searchTerm);
  };

  const buttonStyle = {
    position: "absolute",
    // left: "10px",
    right:"0",
    top: "50%",
    transform: "translateY(-50%)",
    border: "none",
    background: "transparent",
    cursor: "pointer",
    color: "#84818A",
  };

  return (
    <div className="search-bar-container" style={{ position: "relative" }}>
      <input
        style={{ position: "relative" }}
        type="text"
        placeholder="       Search... "
        value={searchTerm}
        onChange={handleInputChange}
        className="search-input"
      />
      <button type="button" style={buttonStyle} onClick={onSearch(searchTerm)}>
         <FaSearch/>
      </button>
    </div>
  );
};

export default SearchBar;
