// import HomeIcon from "../../../assets/Svgs/10470750021656683091 1.png";
// import logo from "../../../assets/eikomp_logo 1.png";
import map from "../../../assets/Homepage/Rectangle 2774.png";
// import frame65 from "../../../assets/Homepage/Frame 65.png"

import "../../../Styles/Pages/Home/Home.css";
// import Navbar from "../Navbar/Navbar";
// import UserProfileModal from "./UserProfileModal/UserProfileModal";

export default function Home() {
  
  

  return (<>
   
   <div className="home-section">
              <span className="home-top-heading">
                Witness the expansive reach of our compliance expertise,
                emphasizing our dedication to delivering comprehensive services
                globally.
              </span>
              <div style={{position:"relative"}}>
                <img src={map} alt="map" className="home-map" />
                {/* <img src={frame65} alt="frame65" className="frame65" /> */}
                </div>
            </div>
  </>
    
  );
}
