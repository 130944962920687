import React, { useState } from "react";
import "../../../../Styles/Pages/ApplicationProgress/IconModal.css";

const IconModal = ({ children, }) => {
  const [modalOpen, setModalOpen] = useState(true);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const modalStyle = {
    display: modalOpen ? "block" : "none",
  };

  return (
    <div className="modal-backdrop" style={modalStyle} onClick={toggleModal}>
      <div
        className="modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

export default IconModal;
