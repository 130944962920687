// import { useState } from "react";
import "../../../../Styles/Pages/KnowYourCompliance/ComplianceCard/ComplianceCard.css";
import Navbar from "../../Navbar/Navbar";
// import bisImg from "../../../../assets/image 6 (2).png";
import { useLocation, useNavigate } from "react-router-dom";


export default function CardInfo() {
  const location = useLocation();
    const res = location.state?.res.data;
  const navigate = useNavigate();

  const directToPlanYourCompliance = () => {
    navigate("/PlanYourCompliance");
  };
 
  return (
    <>
      <Navbar>
        <div style={{ padding: "1em", height: "100vh" }} className="CardInfo-main">
          <section style={{ width: "50%" }}>
            <h2>{res.name}</h2>
            <p>{res.content}</p>
            <button onClick={directToPlanYourCompliance}>
              Plan your Compliance
            </button>
          </section>
          <div className="">
            <img
              src={res.flowchart}
              alt="bisImg"
              style={{ paddingRight: "1em", width: "100%" }}
            />
          </div>
        </div>
      
      </Navbar>
    </>
  );
}
