import React from 'react'
import Navbar from '../Navbar'
import Notification from './Notification'

const NotificationPage = () => {


  return (
    <Navbar>
      <Notification />
    </Navbar>
  )
}

export default NotificationPage
