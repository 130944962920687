import { useEffect, useState } from "react";
import "../../../Styles/Pages/ApplicationProgress/MobileApplicationPage.css";
import axios from "axios";
import jsPDF from "jspdf";
import logo from "../../../assets/eikomp_logo 1.png";
import SearchBar from "./common/SearchBar";
import { useNavigate } from "react-router-dom";


export default function MobileApplicationPage() {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState({ data: [] });
  const [searchedData, setSearchedData] = useState("");

  useEffect(() => {
    async function populateTable() {
      try {
        const res = await axios.get(
          "https://eikompbackend.eikompapp.com/application/application/",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        console.log(res);
        setTableData(res.data);
        console.log(tableData);
      } catch (error) {
        console.log(error);
      }
    }
    populateTable();
  }, []);

  const pdfDownloadHandler = () => {
    const pdf = new jsPDF();

    // const pdfImg = `${logo}`;

    pdf.addImage(logo, "PNG", 10, 10, 25, 25);

    // Add table headers
    pdf.autoTable({
      head: [
        [
          "Unique Number",
          "Registration Number",
          "Product Name",
          "Compliance Name",
          "Manufacturer Name",
          "Renewal Date",
          "Progress",
        ],
      ],
      startY: 40,
      margin: { horizontal: 10 },
    });

    // Add table rows
    const dataRows = tableData.data.map((d) => [
      d.unique_number,
      d.reg_number,
      d.product,
      d.compliance,
      d.manufacturer_name,
      d.valid_upto,
      d.progress,
    ]);

    pdf.autoTable({
      body: dataRows,
      startY: 50,
      margin: { horizontal: 10 },
    });

    // Save the PDF with a specific name
    pdf.save("table.pdf");
  };

  const downloadIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width="16"
      height="16"
      style={{ cursor: "pointer" }}
      onClick={pdfDownloadHandler}
    >
      <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
    </svg>
  );

  const toStatus = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 20 20"
      fill="none"
      style={{transform:"rotate(180deg)",position:"absolute",right:"20px"}}
      
      
    >
      <mask
        id="mask0_121_414"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <path d="M0 0H20V20H0V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_121_414)">
        <path
          d="M0.713867 8.57142V2.14284C0.713867 1.76396 0.864377 1.4006 1.13229 1.13269C1.4002 0.864782 1.76355 0.714272 2.14244 0.714272H17.8567C18.2356 0.714272 18.599 0.864782 18.8669 1.13269C19.1347 1.4006 19.2853 1.76396 19.2853 2.14284V17.8571C19.2853 18.236 19.1347 18.5994 18.8669 18.8673C18.599 19.1351 18.2356 19.2857 17.8567 19.2857H11.4282"
          stroke="#000001"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.71387 19.2857H0.713867V14.2857"
          stroke="#000001"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M0.713867 19.2857L9.99958 9.99998"
          stroke="#000001"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );

  const handleSearch = (searchTerm) => {
    console.log("Searching for:", searchTerm);
    setSearchedData(searchTerm);
  };

  const navigateToStatusHandler = async (id, compliance) => {
    console.log(id);
    localStorage.setItem("user_id", id);
    try {
      const response = await axios.get(
        `https://eikompbackend.eikompapp.com/status?compliance=${compliance}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data);
      console.log(response.data.data.length);
      navigate("/ApplicationProgress/status", {
        state: { response: response.data.data.length },
      });
    } catch (error) {
      console.log(error);
    }
    // navigate("/ApplicationProgress/status", { state: { response: response.data.data.length } });
  };

  return (
    <>
      <SearchBar onSearch={handleSearch} />
      <div className="mobile-application-header">
        <h2>Application Progress</h2>
        {downloadIcon}
      </div>
      <section>
        {tableData.data &&
          tableData.data
            ?.filter(
              (d) =>
                d.product.toLowerCase().includes(searchedData.toLowerCase()) ||
                d.compliance
                  .toLowerCase()
                  .includes(searchedData.toLowerCase()) ||
                d.reg_number
                  .toLowerCase()
                  .includes(searchedData.toLowerCase()) ||
                d.unique_number
                  .toLowerCase()
                  .includes(searchedData.toLowerCase()) ||
                d.valid_upto
                  .toLowerCase()
                  .includes(searchedData.toLowerCase()) ||
                d.progress.toLowerCase().includes(searchedData.toLowerCase()) ||
                d.user.toLowerCase().includes(searchedData.toLowerCase()) ||
                d.manufacturer_name
                  .toLowerCase()
                  .includes(searchedData.toLowerCase())
            )
            .map((d) => (
              <div className="mobile-view-card">
                <span >
                  {" "}
                  <h2 onClick={()=>navigateToStatusHandler(d.id,d.compliance)}>
                    {d.product} {toStatus}<br />
                    <p
                      style={{
                        margin: "0",
                        fontSize: ".875rem",
                        color: "#808080",
                      }}
                    >
                      {d.reg_number}
                    </p>
                  </h2>
                </span>
                <span>
                  {" "}
                  <h3>user</h3>
                  <p>{d.user}</p>
                </span>
                <span>
                  {" "}
                  <h3>Unique Number</h3>
                  <p>{d.unique_number}</p>
                </span>
                <span>
                  {" "}
                  <h3>Status</h3>
                  <p>{d.progress}</p>
                </span>
                <span>
                  {" "}
                  <h3>Compliance Name</h3>
                  <p>{d.compliance}</p>
                </span>
                <span>
                  {" "}
                  <h3>Manufacturer Name</h3>
                  <p>{d.manufacturer_name}</p>
                </span>
                <span>
                  {" "}
                  <h3>Renewal Date</h3>
                  <p>{d.valid_upto}</p>
                </span>
              </div>
            ))}
      </section>
    </>
  );
}
