import React from "react";
import PlanYourCompliance from "./PlanYourCompliance";
import Navbar from "../Navbar/Navbar";

export default function PlanYourCompliancePage(){
    return<>
    <Navbar>
        <PlanYourCompliance/>
    </Navbar>


    </>
}