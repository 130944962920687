import React from 'react';
import "../../../../Styles/Pages/ApplicationProgress/SearchBar.css"

const BottomNavigation = ({ currentPage, totalPages, onPageChange }) => {
  const handlePrevClick = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div style={{ textAlign: 'right', margin: '30px 25px 20px 20px'  }}>
      <button onClick={handlePrevClick} disabled={currentPage === 1} className='button-prev'>
        Prev
      </button>
      <span style={{ margin: '0 10px' }} className='button-no'>{`${currentPage} `}</span>
      <span style={{ margin: '0 10px' }} className='button-no'>{`${totalPages}`}</span>
      <button onClick={handleNextClick} disabled={currentPage === totalPages} className='button-prev'>
        Next
      </button>
    </div>
  );
};

export default BottomNavigation;
