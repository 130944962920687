import React, { useState } from "react";
import stageImg from "../../../assets/planYourCompliance/Radio Button.png";
import "../../../Styles/Pages/PlanYourCompliance/PlanYourCompliance.css";

export default function PlanYourCompliance() {
  const [formData, setFormData] = useState({
    industry: "",
    product_type: "",
    target_country: "",
    date: "",
    time_slot: "",
  });

  const inputStyle = {
    width: "20rem",
    margin: " 0.5rem 1rem 0 0rem",
    color: "#2F2F2F",

    fontFamily: "Poppins",
    fontSize: "0.75rem",
    fontStyle: "normal",
    fontWeight: "400",
  };
  const inputTextStyle = {
    color: "#2F2F2F",

    fontFamily: "Poppins",
    fontSize: "0.75rem",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "1.25rem" /* 166.667% */,
    letterSpacing: "0.03125rem",
    marginTop: "1rem",
  };

  const industryOptions = ["Electronics", "Mechanics", "IT", "Chemical"];

  const handleDropdownChange = (fieldName, event) => {
    const selectedValue = event.target.value;
    setFormData({
      ...formData,
      [fieldName]: selectedValue,
    });
  };

  return (
    <div className="PlanYourCompliance">
      <h3>Select The Stage</h3>
      <img src={stageImg} alt="stageImg" className="stageImg" />

      <div className="input-form">
        <div className="compliance-form-row" style={{ gap: "4rem" }}>
          <div className="Compliance-dropdown">
            <label style={inputTextStyle}>Choose the industry</label>
            <select
              id="industryDropdown"
              value={formData.industry}
              style={inputStyle}
              onChange={(event) => handleDropdownChange("industry", event)}
              className="compliance-input-fields"
            >
              <option
                value=""
                className="compliance-input-fields"
                style={inputStyle}
              >
                Choose the Industry
              </option>
              {industryOptions.map((industry, index) => (
                <option
                  key={index}
                  value={industry}
                  className="compliance-input-fields"
                >
                  {industry}
                </option>
              ))}
            </select>
          </div>

          <div className="Compliance-dropdown">
            <label style={inputTextStyle}>Choose the Product type</label>

            <select
              id="industryDropdown"
              value={formData.product_type}
              style={inputStyle}
              onChange={(event) => handleDropdownChange("product_type", event)}
              className="compliance-input-fields"
            >
              <option
                value=""
                className="compliance-input-fields"
                style={inputStyle}
              >
                Choose the Product type
              </option>
              {industryOptions.map((industry, index) => (
                <option
                  key={index}
                  value={industry}
                  className="compliance-input-fields"
                >
                  {industry}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="dropdown-coloumn">
          <div className="Compliance-dropdown">
            <label style={inputTextStyle}>Choose the Target Country</label>

            <select
              id="industryDropdown"
              value={formData.product_type}
              style={inputStyle}
              onChange={(event) => handleDropdownChange("product_type", event)}
              className="compliance-input-fields"
            >
              <option
                value=""
                className="compliance-input-fields"
                style={inputStyle}
              >
                Choose the Target Country
              </option>
              {industryOptions.map((industry, index) => (
                <option
                  key={index}
                  value={industry}
                  className="compliance-input-fields"
                >
                  {industry}
                </option>
              ))}
            </select>
          </div>
          <div className="Compliance-dropdown">
            <label style={inputTextStyle}>
              Select the Date to schedule Call
            </label>

            <input
            type="date"
            className="compliance-input-fields"
            style={{margin:"0",width:"93%"}}
            />
          </div>
          <div className="Compliance-dropdown">
            <label style={inputTextStyle}>Choose Time Slot</label>

            <select
              id="industryDropdown"
              value={formData.product_type}
              style={inputStyle}
              onChange={(event) => handleDropdownChange("product_type", event)}
              className="compliance-input-fields"
            >
              <option
                value=""
                className="compliance-input-fields"
                style={inputStyle}
              >
                Choose Time Slot
              </option>
              {industryOptions.map((industry, index) => (
                <option
                  key={index}
                  value={industry}
                  className="compliance-input-fields"
                >
                  {industry}
                </option>
              ))}
            </select>
          </div>
          <button className="">Submit Request</button>
        </div>
      </div>
    </div>
  );
}
