import React, { useEffect, useState } from "react"
import '../../../Styles/Pages/Analytics/Analytics.css';
import MultiAxisDemo from "./Graph/MultiAxisGraph";
import DoughnutChartDemo from "./Graph/Circulargraph";
import axios from "axios";

export default function Analytics(){
    const [yearOne, setYearOne] = useState("2022");
    const [yearTwo, setYearTwo] = useState("2023");
    const [analyticsData, setAnalyticsData] = useState([]);
    const [analyticsYearData, setAnalyticsYearData] = useState([]);

    const years = [2020, 2021, 2022, 2023, 2024];

    useEffect(()=>{
        async function getAnlyticData(){
            try {
                const res = await axios.get(`https://eikompbackend.eikompapp.com/application/analytics/?year1=&year2=`,
                {
                    headers: {
                      "Authorization": `Bearer ${localStorage.getItem("token")}`,
                      "Content-Type": "application/json",
                    },
                  }
                );
                console.log(res.data.data);
                setAnalyticsData(res.data.data);
                
            } catch (error) {
                console.log(error);
            }
        }
        getAnlyticData();
    }, []);

    useEffect(()=>{
        async function getYearData(){
            console.log(yearOne,yearTwo)
            try {
                const res = await axios.get(`https://eikompbackend.eikompapp.com/application/analytics/?year1=${yearOne}&year2=${yearTwo}`,
                {
                    headers: {
                      "Authorization": `Bearer ${localStorage.getItem("token")}`,
                      "Content-Type": "application/json",
                    },
                  }
                );
                console.log(res.data.data);
                setAnalyticsYearData(res.data.data);
                
            } catch (error) {
                console.log(error);
            }
        }
        getYearData();
    }, [yearOne,yearTwo]);


    useEffect(()=>{
        console.log(analyticsData);
        console.log(analyticsYearData);
    }, [analyticsData,analyticsYearData]);


    const icon = (<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <rect width="40" height="40" rx="6" fill="#082A71"/>
    <path d="M30 30H10C9.59 30 9.25 29.66 9.25 29.25C9.25 28.84 9.59 28.5 10 28.5H30C30.41 28.5 30.75 28.84 30.75 29.25C30.75 29.66 30.41 30 30 30Z" fill="white"/>
    <path d="M17.75 12V30H22.25V12C22.25 10.9 21.8 10 20.45 10H19.55C18.2 10 17.75 10.9 17.75 12Z" fill="white"/>
    <path d="M11 18V30H15V18C15 16.9 14.6 16 13.4 16H12.6C11.4 16 11 16.9 11 18Z" fill="white"/>
    <path d="M25 23V30H29V23C29 21.9 28.6 21 27.4 21H26.6C25.4 21 25 21.9 25 23Z" fill="white"/>
  </svg>);

  const detailsIcon = (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="0 0 20 24" fill="none" cursor={"pointer"}>
  <path d="M15.6202 9.25C16.7985 9.25 17.752 10.48 17.752 12C17.752 13.52 16.7985 14.75 15.6202 14.75C14.4419 14.75 13.4884 13.52 13.4884 12C13.4884 10.48 14.4419 9.25 15.6202 9.25ZM15.6202 13.25C16.1551 13.25 16.5892 12.69 16.5892 12C16.5892 11.31 16.1551 10.75 15.6202 10.75C15.0853 10.75 14.6512 11.31 14.6512 12C14.6512 12.69 15.0853 13.25 15.6202 13.25Z" fill="#979797"/>
  <path d="M4.76763 9.25C5.94593 9.25 6.89941 10.48 6.89941 12C6.89941 13.52 5.94593 14.75 4.76763 14.75C3.58934 14.75 2.63585 13.52 2.63585 12C2.63585 10.48 3.58934 9.25 4.76763 9.25ZM4.76763 13.25C5.30251 13.25 5.73662 12.69 5.73662 12C5.73662 11.31 5.30252 10.75 4.76763 10.75C4.23275 10.75 3.79864 11.31 3.79864 12C3.79864 12.69 4.23275 13.25 4.76763 13.25Z" fill="#979797"/>
  <path d="M10.1934 9.25C11.3717 9.25 12.3252 10.48 12.3252 12C12.3252 13.52 11.3717 14.75 10.1934 14.75C9.01512 14.75 8.06163 13.52 8.06163 12C8.06163 10.48 9.01512 9.25 10.1934 9.25ZM10.1934 13.25C10.7283 13.25 11.1624 12.69 11.1624 12C11.1624 11.31 10.7283 10.75 10.1934 10.75C9.65853 10.75 9.22442 11.31 9.22442 12C9.22442 12.69 9.65853 13.25 10.1934 13.25Z" fill="#979797"/>
</svg>);

const arrowIcon = (<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
<path d="M0.7475 8.47C0.5575 8.47 0.3675 8.4 0.2175 8.25C-0.0725 7.96 -0.0725 7.48 0.2175 7.19L6.38707 1.01999C6.67707 0.72999 7.15707 0.72999 7.44707 1.01999C7.73707 1.30999 7.73707 1.78999 7.44707 2.07999L1.2775 8.25C1.1275 8.4 0.9375 8.47 0.7475 8.47Z" fill="#1AD944"/>
<path d="M7.7177 6.33C7.3077 6.33 6.9677 5.99 6.9677 5.58V1.5H2.8877C2.4777 1.5 2.1377 1.16 2.1377 0.75C2.1377 0.34 2.4777 0 2.8877 0H7.7177C8.1277 0 8.4677 0.34 8.4677 0.75V5.58C8.4677 5.99 8.1277 6.33 7.7177 6.33Z" fill="#1AD944"/>
</svg>);

// const cardsContent = [{
//     name:"Total Compliances ",
//     number:"56",
//     inc:"+1.25"
// },{
//     name:"Total Compliances ",
//     number:"56",
//     inc:"+1.25"
// },{
//     name:"Total Compliances ",
//     number:"56",
//     inc:"+1.25"
// },{
//     name:"Total Compliances ",
//     number:"56",
//     inc:"+1.25"
// },
// {
//     name:"Total Compliances ",
//     number:"56",
//     inc:"+1.25"
// }];
    return <div style={{width:"95%",margin:"0 auto"}}>
    <h2>Analytics</h2>
    <p>Manage your personal purchases in just one page </p>
    <div className="analytics-cards">
       
        <div className="analytic-card">
        <div className="card-top">{icon} <span> {detailsIcon}</span></div>
        <p>Compliance</p>
        <div className="card-bottom" style={{color:"black",fontSize:"1.25rem",fontWeight:"700"}}>{analyticsData.compliance}<span>+1.25{" "}{arrowIcon}</span></div>
        </div>
        
        <div className="analytic-card">
        <div className="card-top">{icon} <span> {detailsIcon}</span></div>
        <p>Products</p>
        <div className="card-bottom" style={{color:"black",fontSize:"1.25rem",fontWeight:"700"}}>{analyticsData.product}<span>+1.25{" "}{arrowIcon}</span></div>
        </div>
        
       
        
        <div className="analytic-card">
        <div className="card-top">{icon} <span> {detailsIcon}</span></div>
        <p>Inprogress</p>
        <div className="card-bottom" style={{color:"black",fontSize:"1.25rem",fontWeight:"700"}}>{analyticsData.inprogress}<span>+1.25{" "}{arrowIcon}</span></div>
        </div>
        
        <div className="analytic-card">
        <div className="card-top">{icon} <span> {detailsIcon}</span></div>
        <p>Completed</p>
        <div className="card-bottom" style={{color:"black",fontSize:"1.25rem",fontWeight:"700"}}>{analyticsData.completed}<span>+1.25{" "}{arrowIcon}</span></div>
        </div>

        <div className="analytic-card">
        <div className="card-top">{icon} <span> {detailsIcon}</span></div>
        <p>Total</p>
        <div className="card-bottom" style={{color:"black",fontSize:"1.25rem",fontWeight:"700"}}>{analyticsData.total}<span>+1.25{" "}{arrowIcon}</span></div>
        </div>
        

        

    </div>
    <div className="analytics-main">
    <div className="MultiAxisGraph" style={{position:"relative"}}>
        {/* year1 dropdown */}
        <div className="graph-dropdown">
        <label htmlFor="year1">Year 1:</label>
      <select id="year1" value={yearOne} onChange={(event)=>setYearOne(event.target.value)}>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>

      {/* year1 dropdown */}
        <label htmlFor="year1" style={{margin:0}}>Year 2:</label>
      <select id="year1" value={yearTwo} onChange={(event)=>setYearTwo(event.target.value)}>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
      </div>

        


        {analyticsYearData &&  <MultiAxisDemo graphData={analyticsYearData}/>}
        {/* <div className="product-table">
            <h2>Product</h2>
            <table>
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Product Name</th>
                        <th>Sold</th>
                        <th>Price</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>Laptop</td>
                        <td>40000</td>
                        <td>500000</td>
                        <td>done</td>
                    </tr>
                </tbody>
            </table>

        </div> */}
    </div>
    <div style={{display:"flex",flexDirection:"column",gap:"1rem"}}>
        <DoughnutChartDemo graphData={analyticsData}/>
        {/* <div className="product-table">
        <div className="card-top">Last Purchases <span> {detailsIcon}</span></div>
            

        </div> */}
    </div>
    </div>
    </div>
}