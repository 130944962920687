import "../../../Styles/Pages/Home/Home.css";
import Navbar from "../Navbar/Navbar";
import Home from "./Home";
// import UserProfileModal from "./UserProfileModal/UserProfileModal";

export default function HomePage() {
  // const userFirstTime = sessionStorage.getItem("first_time");
  // console.log(userFirstTime);
  
 

  return (<>
   {/* {userFirstTime && <UserProfileModal updateFirstTime={userFirstTime}/>} */}
    <Navbar>
        <Home/>
    
    </Navbar>
    
  </>
    
  );
}