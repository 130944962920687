import React, { useEffect, useState } from "react";
import Navbar from "../Pages/Navbar/Navbar";
import UserProfile from "./UserProfile";
import axios from "axios";

export default function UserProfilePage(){
    const userId = localStorage.getItem("user_id");
    console.log(userId);
    const [userInfo, setUserInfo] = useState({});

    useEffect(()=>{
        async function getUserInfo(){
            try {
                const res  = await axios.get(`https://eikompbackend.eikompapp.com/accounts/user/${userId}/`,
                {
                    headers: {
                      "Authorization": `Bearer ${localStorage.getItem("token")}`,
                      "Content-Type": "application/json",
                    },
                  });
                  console.log(res.data.data);
                  setUserInfo(res.data.data);
                  
            } catch (error) {
                console.log(error);
            }
        }
        getUserInfo();
    }, [userId])

    useEffect(()=>{
        console.log(userInfo);
    }, [userInfo]);
    return <>
    <Navbar>
    {Object.keys(userInfo).length > 0 && <UserProfile userinfo={userInfo} />}
    </Navbar>
    </>
};