import React, { useState, useEffect } from "react";
import axios from "axios";
import IconModal from "../common/IconModal";
import { useLocation } from "react-router-dom";
// import StatusBar from "./ProgressBar";
// import dummy from "../../../../assets/eikomp_logo 1.png";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../../../../assets/eikomp_logo 1.png";
import checklist from "../../../../assets/Checklist.png";
// import { useReactToPrint } from "react-to-print";

export default function ApplicationStatus() {
  const [statusData, setStatusData] = useState([]);
  const [statusImage, setStatusImage] = useState(null);
  const [clickedImage, setClickedImage] = useState(null);
  const [iconActive, setIconActive] = useState(false);
  const [completionPercentage, setCompletionPercentage] = useState(0);

  const extraField = JSON.parse(statusData.extra_fields || "{}");

  const user_Id = localStorage.getItem("user_id");

  const location = useLocation();
  const totalStatusSteps = location.state?.response;

  // const [progress, setProgress] = useState(0);

  // to calcuate inner width

  const innerWidth = window.innerWidth;

  const handleIconClick = () => {
    console.log("icon Clicked");
    setIconActive(!iconActive);
    // setModalOpen(!modalOpen);
  };
  const progressIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 17 17"
      fill="none"
      style={{ position: "absolute", left: "10px", cursor: "pointer" }}
      onClick={handleIconClick}
    >
      <path
        d="M8.45023 1.0625C6.98792 1.0625 5.55844 1.4987 4.34258 2.31594C3.12671 3.13319 2.17905 4.29477 1.61945 5.65379C1.05984 7.01282 0.913426 8.50825 1.19871 9.95098C1.48399 11.3937 2.18816 12.719 3.22218 13.7591C4.25619 14.7993 5.5736 15.5076 7.00782 15.7946C8.44203 16.0816 9.92864 15.9343 11.2796 15.3714C12.6306 14.8084 13.7854 13.8551 14.5978 12.6321C15.4102 11.409 15.8438 9.971 15.8438 8.5C15.8438 6.52745 15.0649 4.63569 13.6783 3.24089C12.2917 1.84609 10.4111 1.0625 8.45023 1.0625ZM8.45023 14.875C7.19682 14.875 5.97156 14.5011 4.92938 13.8006C3.88721 13.1001 3.07493 12.1045 2.59527 10.9396C2.11561 9.77473 1.99011 8.49293 2.23464 7.2563C2.47917 6.01967 3.08275 4.88375 3.96904 3.99219C4.85534 3.10063 5.98455 2.49347 7.21388 2.24749C8.4432 2.00151 9.71743 2.12776 10.8754 2.61027C12.0334 3.09278 13.0232 3.90988 13.7196 4.95824C14.4159 6.0066 14.7876 7.23914 14.7876 8.5C14.7876 10.1908 14.1199 11.8123 12.9314 13.0078C11.7429 14.2034 10.131 14.875 8.45023 14.875Z"
        fill="black"
      />
      <path d="M7.92188 4.25H8.9781V10.0938H7.92188V4.25Z" fill="black" />
      <path
        d="M8.4494 11.6875C8.29272 11.6875 8.13956 11.7342 8.00929 11.8218C7.87902 11.9094 7.77748 12.0338 7.71753 12.1794C7.65757 12.325 7.64188 12.4853 7.67245 12.6398C7.70301 12.7944 7.77846 12.9364 7.88925 13.0479C8.00004 13.1593 8.14119 13.2352 8.29485 13.2659C8.44852 13.2967 8.6078 13.2809 8.75255 13.2206C8.8973 13.1603 9.02102 13.0581 9.10806 12.9271C9.19511 12.796 9.24157 12.642 9.24157 12.4844C9.24157 12.273 9.15811 12.0703 9.00955 11.9209C8.86099 11.7715 8.65949 11.6875 8.4494 11.6875Z"
        fill="black"
      />
    </svg>
  );

  const progressDone = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 64 64"
      enableBackground="new 0 0 64 64"
      style={{ position: "absolute", left: "10px", cursor: "pointer" }}
      onClick={handleIconClick}
    >
      <path
        d="M32,2C15.431,2,2,15.432,2,32c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30C62,15.432,48.568,2,32,2z M25.025,50
l-0.02-0.02L24.988,50L11,35.6l7.029-7.164l6.977,7.184l21-21.619L53,21.199L25.025,50z"
        fill="#43a047"
      />
    </svg>
  );

 

  useEffect(() => {
    async function populateStatus() {
      try {
        const response = await axios.get(
          `https://eikompbackend.eikompapp.com/application/application/${user_Id}/`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("API response:", response.data.data);

        // Set state using the callback form to ensure the latest state
        setStatusData(response.data.data);
        setStatusImage(response.data.data.status);
      } catch (error) {
        console.error(error);
      }
    }

    populateStatus();
  }, [user_Id]); // Include 'user_Id' in the dependency array

  useEffect(() => {
    updateProgressBar(statusData);
  }, [statusData]);

  const updateProgressBar = (statusData) => {
    if (statusData && statusData.status) {
      const statusCount = statusData.status.filter(
        (status) => status.progress === "completed"
      ).length;
      const progressBar = document.getElementById("dynamicProgressBar");
      const percentage = (statusCount / totalStatusSteps) * 100;

      setCompletionPercentage(percentage);

      // Check if progressBar exists before updating
      if (progressBar) {
        const completionPercentage = (statusCount / totalStatusSteps) * 100;

        // Update the width and text of the progress bar
        progressBar.style.width = `${completionPercentage.toFixed(2)}%`;

        const progressBarText = progressBar.nextElementSibling;
        if (progressBarText) {
          progressBarText.textContent = `${completionPercentage.toFixed(
            2
          )}% Completed`;
        }
      } else {
        console.error("Progress bar element not found");
      }
    }
  };

  // Function to render a single data row
  const renderDataRow = (data) => (
    <div key={data.id} className="status-table">
      <p>
        Username :<span>{data.user}</span>
      </p>
      <p>
        Compliance :<span>{data.compliance}</span>
      </p>
      <p>
        Manufacturer Name :<span>{data.manufacturer_name}</span>
      </p>
      <p>
        product :<span>{data.product}</span>
      </p>
      <p>
      Certificate Number :<span>{data.unique_number}</span>
      </p>
      <p>
      Tracking Id :<span>{data.reg_number}</span>
      </p>
      
      <p>
        Submission Date :<span>{data.submission_date}</span>
      </p>
      <p>
        Renewal Date :<span>{data.valid_upto}</span>
      </p>
      <p>
        progress :<span>{data.progress}</span>
      </p>
      {/* <p>
        
      </p> */}
      {/* <td>{data.unique_number}</td>

      <td>{data.reg_number}</td>
      <td>{data.product}</td>
      <td>{data.compliance}</td>
      <td>{data.submission_date}</td>
      <td>{data.manufacturer_name}</td>
      <td>{data.completion_date}</td>
      <td>{data.valid_upto}</td> */}
    </div>
  );

  // const progressTable = (data) => (
  //   <tr key={data.id}>
  //     <td>{data.user}</td>
  //     <td>{data.unique_number}</td>
  //     <td>{data.reg_number}</td>
  //     <td>{data.product}</td>
  //     <td>{data.compliance}</td>
  //     <td>{data.submission_date}</td>
  //     <td>{data.manufacturer_name}</td>
  //     <td>{data.completion_date}</td>
  //     <td>{data.valid_upto}</td>
  //   </tr>
  // );
  //   const progressTable = (data,index) => [
  //     index+1,
  //     data.id,
  //       data.user,
  //       data.unique_number,
  //       data.reg_number,
  // data.product,
  //       data.compliance
  //       // data.submission_date
  //       // <td>{data.manufacturer_name}</td>
  //       // <td>{data.completion_date}</td>
  //       // <td>{data.valid_upto}</td>

  //   ];

  const handleDownloadPDF = () => {
    // Assuming statusData.certificate is an array and you want to download the first certificate
    if (statusData.certificate && statusData.certificate.length > 0) {
      const pdfUrl = statusData.certificate[0].certificate; // Get the URL of the first certificate

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.target = "_blank"; // Open the link in a new tab
      link.download = "certificate.pdf"; // You can specify the file name here
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.log("No certificate URL found");
    }
  };

const handleReportDownloadPDF = () => {
  if (statusData.reports && statusData.reports.length > 0) {
    statusData.reports.forEach((report, index) => {
      const pdfUrl = report.report;

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.target = "_blank";
      link.download = `report_${index + 1}.pdf`; // Assign unique file names
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  } else {
    console.log("No report URL found");
  }
};


  // const exportPdf = useReactToPrint({
  //   content: () => pdfcomponent.current,
  //   documentTitle: "prog.pdf",
  //   onAfterPrint: () => alert("data saved in pdf"),
  // });

  const handleDownloadClick = () => {
    const doc = new jsPDF("landscape");

    doc.addImage(logo, "PNG", 10, 10, 25, 25);
    const headers = [
      "User Id",
      "User",
      "Unique Number",

      "Registration Number",
      "Product",
      "Compliance",
      "Submission Date",
      "Manufacturer Name",
      "Renewal Date",
      "Status",
    ];
    console.log(statusData, "row wala");

    const rows = [
      statusData.id,
      statusData.user,
      statusData.unique_number,
      statusData.reg_number,
      statusData.product,
      statusData.compliance,
      statusData.submission_date,
      statusData.manufacturer_name,
      statusData.valid_upto,
      statusData.progress,
    ];
    console.log(rows);
    const columnWidth = [3, 25, 25, 30, 25, 40, 25, 35, 20, 35];
    const rowHeight = 6;

    doc.autoTable({
      head: [headers],
      body: [rows],
      startY: 40,
      columnWidth: columnWidth,
      rowHeight: rowHeight,
      styles: { cellPadding: 2, valign: "middle", halign: "center" },
    });

    doc.save("Track Application History.pdf");
  };

  const downloadDatahandler = () => {
    const doc = new jsPDF("landscape");
    doc.addImage(logo, "PNG", 250, 10, 25, 25);

    doc.text(`User: ${statusData.user}`, 10, 20);
    doc.text(`Compliance: ${statusData.compliance}`, 10, 30);
    doc.text(`Product: ${statusData.product}`, 10, 40);
    doc.text(`Registration Number: ${statusData.reg_number}`, 10, 50);

    const headers = ["Status", "Start Date", "End Date", "Message"];

    const rowData = statusData.status.map((d) => [
      d.status.name,
      d.start_date,
      d.end_date,
      d.message,
    ]);

    console.log(rowData, "download Data");

    const columnWidth = [3, 25, 25, 30, 25, 40, 25, 35, 20, 35];
    const rowHeight = 6;

    doc.autoTable({
      head: [headers],
      body: rowData,
      columnWidth: columnWidth,
      startY: 70,
      rowHeight: rowHeight,
      styles: { cellPadding: 2, valign: "middle", halign: "center" },
    });

    doc.save("Data.pdf");
  };

  const imageIconHandler = (status) => {
    setClickedImage(status);
    console.log(clickedImage);
  };


  // svgs//

  const uploadDataIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M4 12V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V12"
        stroke="#1A1A1A"
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16 6L12 2L8 6"
        stroke="#1A1A1A"
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 2V15"
        stroke="#1A1A1A"
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
  const downloadDataIcon = (
    <svg
      width="28"
      height="28"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={downloadDatahandler}
    >
      <path
        d="M3 15C3 17.8284 3 19.2426 3.87868 20.1213C4.75736 21 6.17157 21 9 21H15C17.8284 21 19.2426 21 20.1213 20.1213C21 19.2426 21 17.8284 21 15"
        stroke="#1A1A1A"
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 3V16M12 16L16 11.625M12 16L8 11.625"
        stroke="#1A1A1A"
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  return (
    <>
      {iconActive && (
        <IconModal>
          <div style={{ textAlign: "center", fontSize: "20px" }}>
            <p>Start Date : {clickedImage.start_date}</p>
            <p>End Date : {clickedImage.end_date}</p>
            <p>Message : {clickedImage.message}</p>
          </div>
        </IconModal>
      )}
      <div style={{ background: "#F2F4F6", paddingBottom: "4rem" }}>
        <div className="appilicationProgress-header">
          <h2>{statusData.compliance} Modification Application</h2>
          <div className="mobileStatus-header">
            {uploadDataIcon}
            {" "}
            {downloadDataIcon}
          </div>
          <div className="Progress-header">
            {/* <button>
              Upload Data{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
              >
                <path
                  d="M1 9.68359V14.4204C1 15.2925 1.79009 15.9994 2.76471 15.9994H15.1176C16.0923 15.9994 16.8824 15.2925 16.8824 14.4204V9.68359"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.94301 12.0526V1M8.94301 1L4.53125 5.29826M8.94301 1L13.3548 5.29824"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button> */}
            <button onClick={downloadDatahandler}>Download Status Progress</button>
          </div>
        </div>

        <div style={{ paddingLeft: "2rem", textAlign: "center" }}>
          <div
            style={{
              width: "90%",
              border: "1px solid #ccc",
              borderRadius: "5px",
              overflow: "hidden",
            }}
          >
            <div
              id="dynamicProgressBar"
              style={{
                width: "0%",
                background: "#0066FF",
                height: "15px",
                transition: "width 0.3s ease-in-out",
              }}
            ></div>
          </div>
          <p>{`${Math.floor(completionPercentage)}% Completed`}</p>
        </div>

        {innerWidth < 500 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2.5rem",
              margin: "2rem 0 1rem 0",
            }}
          >
            {statusImage &&
              statusImage.length > 0 &&
              statusImage.map((d) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <span onClick={() => imageIconHandler(d)}>
                    {d.progress === "completed" ? progressDone : progressIcon}{" "}
                    <img
                      src={checklist}
                      alt="img"
                      className="checklist-status"
                    />
                  </span>
                  <span
                    style={{
                      fontSize: "1rem",
                      fontWeight: "600",
                    }}
                  >
                    {d.status.name}
                  </span>
                  {/* <span>{d.status.start_date}</span> */}
                </div>
              ))}
          </div>
        ) : (
          <div className="status-img-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "2rem",
                width: "70%",
                flexWrap: "wrap",
              }}
            >
              {/* <img src={dummy} alt="dummy" />
          <img src={dummy} alt="dummy" />
          <img src={dummy} alt="dummy" />
          <img src={dummy} alt="dummy" />
          <img src={dummy} alt="dummy" />
          <img src={dummy} alt="dummy" />
          <img src={dummy} alt="dummy" /> */}
              {statusImage && statusImage.length > 0 ? (
                statusImage.map((d) => (
                  <div
                    key={d.status.id}
                    style={{
                      textAlign: "center",
                      background: "#D9D9D9",
                      borderRadius: "20px",
                      padding: "1em",
                      width: "8rem",
                      position: "relative",
                    }}
                  >
                    <span onClick={() => imageIconHandler(d)}>
                      {" "}
                      {d.progress === "completed" ? progressDone : progressIcon}
                    </span>
                    <img src={d.status.image} alt="img" />
                    <p style={{ margin: "0", fontSize: "15px" }}>
                      {d.status.name}
                    </p>
                  </div>
                ))
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    marginTop: "20px",
                    width: "100%",
                    fontWeight: "700",
                  }}
                >
                  Your Application is not started yet !
                </div>
              )}
            </div>
          </div>
        )}

        {/* progress table */}

        {/* <div ref={pdfcomponent} className="application-table">
          <table>
            <thead>
              <tr>
                <th className="table-head">User</th>
                <th className="table-head">Unique Number</th>
                <th className="table-head">Registration Number</th>
                <th className="table-head">product Name</th>
                <th className="table-head">Compliance Name</th>
                <th className="table-head">Submission Date</th>
                <th className="table-head">Manufacturer Name</th>
                <th className="table-head">Completion Date</th>
                <th className="table-head">Renewal Date</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(statusData) ? (
                statusData.map((data) => progressTable(data))
              ) : statusData ? (
                progressTable(statusData)
              ) : (
                <tr>
                  <td colSpan="6">No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div> */}

        {/* progress table */}
        <div
          className="Progress-header"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "2rem",
          }}
        >
          <button onClick={handleDownloadClick}>
          Download Application Data
          </button>
          <button onClick={handleReportDownloadPDF}>Download Test Report</button>
          <button onClick={handleDownloadPDF}>Download Certificate</button>
        </div>

        <div
          className="application-table"
          style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
        >
          <h2
            style={{
              fontSize: "1.25rem",
              fontWeight: "600",
              margin: "1rem auto",
              width: "85%",
              textAlign: "left",
              padding: "1rem 0 0 0",
            }}
          >
            Application Information
          </h2>
          <div>
            {console.log(statusData)}
            {Array.isArray(statusData) ? (
              statusData.map((data) => renderDataRow(data))
            ) : statusData ? (
              renderDataRow(statusData)
            ) : (
              <p>"No Data"</p>
            )}
          </div>
        </div>
        <br />
        <div
          className="application-table"
          style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
        >
          <h2
            style={{
              fontSize: "1.25rem",
              fontWeight: "600",
              margin: "1rem auto",
              width: "85%",
              textAlign: "left",
              padding: "1rem 0 0 0",
            }}
          >
            Application Extra Fields
          </h2>
          <div>
            {/* <div className="status-table"> */}
            {extraField &&
              Object.entries(extraField).map(([key, value]) => (
                <div
                  className="status-table"
                  style={{ justifyContent: "start", height: "auto" }}
                >
                  <p>
                    {key} :<span>{value}</span>
                  </p>
                </div>
              ))}
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
