import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../../../Styles/Notification/Notification.css";

export default function Notification({ notification, markAsReadProp }) {
  const [notifications, setNotifications] = useState([]);
  const [renewalNotification, setRenewalNotifications] = useState([]);
  const [govtNotification, setGovtNotifications] = useState([]);

  useEffect(() => {
    axios
      .get(`https://eikompbackend.eikompapp.com/application/notification/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setNotifications(response.data.data);
        } else {
          alert("Failed to retrieve notifications:", response.data.message);
        }
      })
      .catch((error) => {
        alert("Error retrieving notifications:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`https://eikompbackend.eikompapp.com/renewal/track/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setRenewalNotifications(response.data.data);
        } else {
          alert("Failed to retrieve notifications:", response.data.message);
        }
      })
      .catch((error) => {
        alert("Error retrieving notifications:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`https://eikompbackend.eikompapp.com/notification/govt/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setGovtNotifications(response.data.data);
        } else {
          alert("Failed to retrieve notifications:", response.data.message);
        }
      })
      .catch((error) => {
        alert("Error retrieving notifications:", error);
      });
  }, []);

  const markAsRead = (id) => {
    axios
      .put(
        `https://eikompbackend.eikompapp.com/renewal/track/${id}/`,
        { is_read: true },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.status === "success") {
          setRenewalNotifications((prevNotifications) =>
            prevNotifications.map((notification) =>
              notification.id === id
                ? { ...notification, is_read: true }
                : notification
            )
          );
        } else {
          alert("Failed to mark notification as read:", response.data.message);
        }
      })
      .catch((error) => {
        alert("Error marking notification as read:", error);
      });
  };

  const handleOpenLink = (url) => {
    if (url) {
      window.open(url, "_blank");
    }
  };

  const downloadImage = async (imageUrl) => {
    console.log("Image URL:", imageUrl); // Log the URL to verify

    try {
      const response = await fetch(imageUrl);
      if (!response.ok) {
        throw new Error("Failed to download image");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "image.jpg"); // Set the filename for the downloaded image
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
      // If an error occurs, open the image in a new browser tab
      window.open(imageUrl, "_blank");
    }
  };

  return (
    <div className="notification-main">
      <h1 className="notification-table-title">Notifications</h1>
      <table className="application-table">
        <thead>
          <tr>
            <th className="table-head">Project Code</th>
            <th className="table-head">Product</th>
            <th className="table-head">Compliance</th>
            <th className="table-head">Message</th>
          </tr>
        </thead>
        <tbody>
          {notifications.applications &&
            notifications.applications.map((notification, index) => (
              <tr key={index}>
                <td>{notification.project_code}</td>
                <td>{notification.product}</td>
                <td>{notification.compliance}</td>
                <td>{notification.message}</td>
              </tr>
            ))}
          {notifications.reports &&
            notifications.reports.map((notification, index) => (
              <tr key={index}>
                <td>{notification.project_code}</td>
                <td>{notification.product}</td>
                <td>{notification.compliance}</td>
                <td>{notification.message}</td>
              </tr>
            ))}
          {notifications.certificates &&
            notifications.certificates.map((notification, index) => (
              <tr key={index}>
                <td>{notification.project_code}</td>
                <td>{notification.product}</td>
                <td>{notification.compliance}</td>
                <td>{notification.message}</td>
              </tr>
            ))}

            </tbody>
            </table>

          {/* here is renewal Notification table data */}
          <h1 className="notification-table-title">Renewal Notification</h1>
          <table className="application-table">
        <thead>
          <tr>
          <th className="table-head">Certificate Number</th>
            <th className="table-head">Product Name</th>
            <th className="table-head">Manufacturer Namw</th>
            <th className="table-head">Model Number</th>
           
            <th className="table-head">Expiry Date</th>
            <th className="table-head">Certificate</th>
          </tr>
        </thead>
        <tbody>

          {renewalNotification.map((notification, index) => (
            <tr
              key={index}
              className={notification.is_read ? "" : "unread"}
              onClick={() => markAsRead(notification.id)}
            >
             <td>{notification.application.unique_number}</td>
              <td>{notification.application.product}</td>
              <td>{notification.application.manufacturer_name}</td>
              <td>
      {notification.application.extra_fields &&
        JSON.parse(notification.application.extra_fields).model_number}
    </td>
             
              <td>{notification.application.valid_upto}</td>
              <td>
                {notification.application.certificate &&
                  notification.application.certificate.map(
                    (cert, certIndex) =>
                      cert &&
                      cert.certificate && (
                        <button
                          key={certIndex}
                          onClick={() => handleOpenLink(cert.certificate)}
                        >
                          Download Certificate {certIndex}
                        </button>
                      )
                  )}
                {/* {notification.application.reports &&
                  notification.application.reports.map(
                    (report, reportIndex) =>
                      report &&
                      report.report && (
                        <button
                          key={reportIndex}
                          onClick={() => handleOpenLink(report.report)}
                        >
                          Report {reportIndex}
                        </button>
                      )
                  )} */}
              </td>
            </tr>
          ))}


       </tbody>
       </table>

          {/* Govt Notification Table data here  */}
          <h1 className="notification-table-title">Govt Notifications</h1>
          <table className="application-table">
        <thead>
          <tr>
            <th className="table-head">Project Code</th>
            <th className="table-head">Product</th>
            <th className="table-head">Compliance</th>
            <th className="table-head">Message</th>
          </tr>
        </thead>
        <tbody>

          {govtNotification.map((notification, index) => (
            <tr key={index}>
              <td>{notification.title}</td>
              <td>{notification.product.name}</td>
              <td>{notification.product.category}</td>
              <td>
                <button onClick={() => downloadImage(notification.file)}>
                  Download Image
                </button>
              </td>
            </tr>
          ))}

          </tbody>
          </table>

   {/* Message when there are no new notifications */}
{(!notifications.applications || notifications.applications.length === 0) &&
 (!notifications.reports || notifications.reports.length === 0) &&
 (!notifications.certificates || notifications.certificates.length === 0) &&
 renewalNotification.length === 0 &&
 govtNotification.length === 0 && (
  <tr>
    <td colSpan="4">No new notifications</td>
  </tr>
)}

    </div>
  );
}
