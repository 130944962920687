import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Input from "../Input/Input";
import logo from "../../assets/eikomp_logo 1.png";
import topImg from "../../assets/front-view-young-female-courier-green-uniform-smiling-holding-package-with-food_140725-21833 2 (1).png";
import "../../Styles/Login/Login.css";
import ToggleButton from "./ToggleBtn/ToggleButton";
import { FaEyeSlash } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";
import color1 from "../../assets/login/circle 1 (1).png";
import color2 from "../../assets/login/circle 2 (1).png";
import ellipse from "../../assets/login/Ellipse 8.png";
import Swal from 'sweetalert2';
// import indicator from "../../assets/indicator.png";

export default function Login() {
  const navigate = useNavigate();

  const [isRegister, setIsRegister] = useState(false);

  const [showPassword, setShowPassword] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleEmailChange = (fieldName, value) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setFormData({
      ...formData,
      password: newPassword,
    });
    setShowPassword("");
  };

  const submitHandler = (event) => {
    event.preventDefault();
    console.log(formData);
  };

  const directToSignup = () => {
    navigate("/Signup");
  };
  const inputStyle = {
    position: "relative",
  };

  const buttonStyle = {
    position: "absolute",
    right: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    border: "none",
    background: "transparent",
    cursor: "pointer",
  };

  async function loginsubmitHandler() {
  
    try {
      const response = await axios.post(
        'https://eikompbackend.eikompapp.com/login',
        {
          email: formData.email,
          password: formData.password,
        }
      );
        debugger;
      console.log(response);
  
      const status = response.status;

      sessionStorage.setItem("username",response.data.username);
      sessionStorage.setItem("first_time",response.data.first_time);
      localStorage.setItem("user_id",response.data.data.user.id);
      
      localStorage.setItem("token",response.data.data.access);
    
      console.log(localStorage.getItem("token"));
     
  
      if (status === 200) {
        // Successful login
        navigate('/Home');
      } else {
        // Handle unsuccessful login (e.g., incorrect credentials)
        console.error('Login failed. Status:', status);
        setIsRegister(true);
      }
    } catch (error) {
      // Handle other errors (e.g., network issues)
      console.error('Error:', error.message);
      Swal.fire({
        icon: 'error',
        title: 'Login Failed',
        text: "User does not exist",
      })
      setIsRegister(true);
    }
  }

  return (
    <div className="login-main">
      <section className="login-top">
      <img src={color2} alt="color2" className="color2"/>
      <img src={color2} alt="color3" className="color3"/>
        <img src={color1} alt="color1" className="color1"/>
        <img src={ellipse} alt="ellipse" className="ellipse"/>
        <div className="log-ininternal-box">
          <p>
            Click to comply - <br />
            Revolutionizing Product <br />
            Compliance with a Single Tab
          </p>
          <img src={topImg} alt="topImg" className="Login-girlImg" />
        </div>

        {/* <img src={indicator} alt="indicator" className="indicator"/> */}
      </section>
      <div className="login-form">
        <div className="form-top">
          <img src={logo} alt="eikomp-logo" />
          <h2 style={{ fontSize: "2rem", width:"auto" }}>Welcome Back</h2>
          <h3>Login into your account</h3>
        </div>
        <div className="form-inputs">
          <form onSubmit={submitHandler}>
            <Input
              alt="Email"
              type="text"
              title="Email"
              placeholder="Enter your Email"
              onInputChange={handleEmailChange}
              fieldName="email"
              value={formData.email}
            />
            <br />
            {/* <Input
              type="password"
              title="Password"
              placeholder="Enter your pasword"
              onInputChange={handleEmailChange}
              fieldName="password"
              value={formData.password}
            /> */}
            <div className="inputs">
              <div className="inputs-left">
                <span>Password</span>
              </div>
              <div className="input-fields" style={inputStyle}>
                <input
                  type={showPassword ? "text" : "password"}
                  value={formData.password}
                  onChange={handlePasswordChange}
                  placeholder="Enter password"
                  required
                />
                <button
                  type="button"
                  style={buttonStyle}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
            </div>
            <span style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between"}}>
              <ToggleButton />
              <Link style={{ cursor: "pointer",textDecoration:"none" }}>Recover Password</Link>
            </span>
            <button className="login-btn" onClick={loginsubmitHandler}>
              Login
            </button>
          </form>
          {/* {isRegister &&  Swal.fire({
        icon: 'error',
        title: 'Login Failed',
        text: 'Invalid username or password. Please try again.',
      })
      } */}
        </div>
        <div className="form-footer">
          Don't have an account?{" "}
          <span
            style={{ cursor: "pointer", fontWeight: "500", fontSize: "16px" }}
            onClick={directToSignup}
          >
            Sign Up!
          </span>
        </div>
      </div>
    </div>
  );
}
