import { useEffect, useRef, useState } from "react";
import "../../../Styles/Pages/ApplicationProgress/ApplicationProgress.css";
import BottomNavigation from "./common/BottomBtn";
// import SearchBar from "./common/SearchBar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import logo from "../../../assets/eikomp_logo 1.png";
import SearchBar from "./common/SearchBar";

// Function to safely parse the extra_fields JSON and extract the model number
const getModelNumber = (extraFields) => {
  try {
    const parsedFields = JSON.parse(extraFields);
    return parsedFields.model_number || ''; // Return the model_number or an empty string if it doesn't exist
  } catch (e) {
    // In case of parsing error, return an empty string
    return '';
  }
};

export default function ApplicationProgress() {
  const pdfcomponent = useRef();

  const navigate = useNavigate();
  const [tableData, setTableData] = useState({ data: [] });
  // const [searchedData, setSearchedData] = useState([]);
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  // const [searchedData, setSearchedData] = useState()

  const handleSearch = (searchTerm) => {
    // Perform the search logic here using the searchTerm
    console.log("Searching for:", searchTerm);
    // setSearchedData(searchTerm);
    // console.log(tableData.data);

  //  if(searchTerm)
  //  { const filteredData =
  //     tableData.data &&
  //     tableData.data?.filter(
  //       (d) =>
  //         d.product.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //         d.compliance.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //         d.reg_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //         d.unique_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //         d.progress.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //         d.user.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //         d.manufacturer_name.toLowerCase().includes(searchTerm.toLowerCase())
  //     );
  //      setSearchedData(filteredData);
  //   }

    // console.log(filteredData);
  
    // console.log(searchedData)
  };

  const handlePageChange = (page) => {
    // setCurrentPage(page);
    // fetchTableData(page);
  };
  useEffect(() => {
    async function populateTable() {
      try {
        const res = await axios.get(
          "https://eikompbackend.eikompapp.com/application/application/",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        console.log(res);
        setTableData(res.data);
        // console.log(tableData);
      } catch (error) {
        console.log(error);
      }
    }
    populateTable();
  }, []);

  const handlerUserClick = async (id, compliance) => {
    console.log(id);
    localStorage.setItem("user_id", id);
    try {
      const response = await axios.get(
        `https://eikompbackend.eikompapp.com/status?compliance=${compliance}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data);
      console.log(response.data.data.length);
      navigate("/ApplicationProgress/status", {
        state: { response: response.data.data.length },
      });
    } catch (error) {
      console.log(error);
    }
    // navigate("/ApplicationProgress/status", { state: { response: response.data.data.length } });
  };

  // const exportPdf = useReactToPrint({
  //   content: () => pdfcomponent.current,
  //   documentTitle: "prog.pdf",
  //   onAfterPrint: () => alert("data saved in pdf"),
  // });

  // const handleButtonClick = () => {
  //   // Trigger the print and download
  //   exportPdf();
  // };

  const downloadPDF = () => {
    const pdf = new jsPDF();

    // const pdfImg = `${logo}`;

    pdf.addImage(logo, "PNG", 10, 10, 25, 25);

    // Add table headers
    pdf.autoTable({
      head: [
        [
          "Unique Number",
          "Registration Number",
          "Product Name",
          "Compliance Name",
          "Manufacturer Name",
          "Renewal Date",
          "Progress",
        ],
      ],
      startY: 40,
      margin: { horizontal: 10 },
    });

    // Add table rows
    const dataRows = tableData.data.map((d) => [
      d.unique_number,
      d.reg_number,
      d.product,
      d.compliance,
      d.manufacturer_name,
      d.valid_upto,
      d.progress,
    ]);

    pdf.autoTable({
      body: dataRows,
      startY: 50,
      margin: { horizontal: 10 },
    });

    // Save the PDF with a specific name
    pdf.save("table.pdf");
  };

  const handleFilterButtonClick = () => {
    setShowFilterDropdown(!showFilterDropdown);
  };

  const handleCheckboxChange = (checkbox) => {
    const updatedCheckboxes = [...selectedCheckboxes];

    if (updatedCheckboxes.includes(checkbox)) {
      // Remove checkbox if already selected
      updatedCheckboxes.splice(updatedCheckboxes.indexOf(checkbox), 1);
    } else {
      // Add checkbox if not selected
      updatedCheckboxes.push(checkbox);
    }

    setSelectedCheckboxes(updatedCheckboxes);
  };


  const renderFilterDropdown = () => {
    if (!showFilterDropdown) {
      return null;
    }

    return (
      <div className="filter-dropdown">
        <label>
          <input
            type="checkbox"
            checked={selectedCheckboxes.includes("compliance")}
            onChange={() => handleCheckboxChange("compliance")}
          />
          Compliance Name
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedCheckboxes.includes("product")}
            onChange={() => handleCheckboxChange("product")}
          />
          Product Name
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedCheckboxes.includes("uniqueNumber")}
            onChange={() => handleCheckboxChange("uniqueNumber")}
          />
          Unique Number
        </label>
        
      </div>
    );
  };


  return (
    <>
      <div className="appilicationProgress-header">
        <h2>Application Progress</h2>
        <div className="Progress-header">
          <SearchBar onSearch={handleSearch} /> 
           <div style={{position:"relative"}}><button onClick={handleFilterButtonClick}>Filter</button>
           {renderFilterDropdown()}</div>
          <button onClick={downloadPDF}>+Download Data</button>
        </div>
      </div>
      <div className="application-table" ref={pdfcomponent}>
        <table>
          {/* <th>company Type</th> */}
          <thead>
            <tr>
            <th className="table-head">Unique Id</th>
              <th className="table-head">Certificate Number</th>
              <th className="table-head">Application Number</th>
              <th className="table-head">Product Name</th>

              <th className="table-head">Compliance Name</th>
              <th className="table-head">Manufacturer Name</th>
              <th className="table-head">Model Number</th>
              <th className="table-head">Renewal Date</th>

              <th className="table-head">Progress</th>
            </tr>
          </thead>
          <tbody>
          {tableData.data &&
    tableData.data
        .sort((a, b) => {
            const codeA = parseInt(a.project_code.split('/').pop().split('-').pop());
            const codeB = parseInt(b.project_code.split('/').pop().split('-').pop());
            return codeA - codeB;
        })
        .map((d) => (
            <tr key={d.id}>
                <td
                    onClick={() => handlerUserClick(d.id, d.compliance)}
                    style={{ cursor: "pointer" }}
                    className="tableClickableData"
                >
                    {d.project_code}
                </td>
                {/* unique number data */}
                <td>{d.unique_number}</td>
                <td>{d.reg_number}</td>
                <td>{d.product}</td>
                {/* Compliance Name*/}
                <td>{d.compliance}</td>
                {/* Submission Date*/}
                <td>{d.manufacturer_name}</td>
                <td>{getModelNumber(d.extra_fields)}</td>
                <td>{d.valid_upto}</td>
                {/* Manufacturer Name*/}
                {/* Completion Date*/}
                <td>{d?.progress}</td>
            </tr>
        ))}

          </tbody>
        </table>
      </div>
      {tableData.data.length>10 && <BottomNavigation
        currentPage={"1"}
        totalPages={"2"}
        onPageChange={handlePageChange}
      />}
    </>
  );
}
